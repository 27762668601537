import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Transition } from "react-transition-group"
import { PDFObject } from "react-pdfobject"
import Close from "../components/Close"

const TRANSITION_DURATION = 400

const DEFAULT_STYLE = {
  transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0.25, 0, 1), opacity ${TRANSITION_DURATION}ms ease`,
  transform: "translate3d(0, 30px, 0)",
  opacity: 0,
}

const TRANSITION_STYLES = {
  entering: {
    transform: "translate3d(0, 30px, 0)",
    opacity: 0,
  },
  entered: {
    transform: "translate3d(0, 0, 0)",
    opacity: 1,
  },
  exited: {
    transform: "translate3d(0, 0, 0)",
    opacity: 0,
  },
  exiting: { transform: "translate3d(0, 0, 0)" },
}

export default () => {
  const [currentPDF, setCurrentPDF] = useState(null)
  const [currentImage, setCurrentImage] = useState(null)

  const data = useStaticQuery(graphql`
    {
      allSanityPressPage(filter: { _id: { eq: "pressPage" } }) {
        nodes {
          title
          press {
            _key
            year
            publication
            link
            headline
            image {
              asset {
                url
              }
            }
            pdf {
              asset {
                url
              }
            }
          }
          _id
        }
      }
    }
  `)

  const { press = [] } = data.allSanityPressPage.nodes[0]

  return (
    <Layout>
      <SEO title="Press" />
      <Transition
        in={currentPDF || currentImage}
        mountOnEnter
        unmountOnExit
        appear
        timeout={{
          enter: 100,
          exit: TRANSITION_DURATION,
        }}
      >
        {state => (
          <div
            className={classNames("pdf__wrapper", {
              open: currentPDF || currentImage,
            })}
            onClick={e => {
              const { currentTarget } = e
              if (currentTarget.id !== "pdfobject") {
                setCurrentPDF(null)
                setCurrentImage(null)
              }
            }}
          >
            <button
              className="pdf__close"
              onClick={() => {
                setCurrentPDF(null)
                setCurrentImage(null)
              }}
            >
              <Close />
            </button>
            {currentImage && (
              <article className="press__image pf x">
                <picture>
                  <source
                    srcSet={`${currentImage.image.asset.url}?w=1600&auto=format&q=75`}
                    media="(min-width: 1000px)"
                  />
                  <source
                    srcSet={`${currentImage.image.asset.url}?w=1000&auto=format&q=75`}
                    media="(min-width: 600px)"
                  />
                  <img
                    alt={currentImage.headline}
                    className="db"
                    src={`${currentImage.image.asset.url}?w=600&auto=format`}
                  />
                </picture>
                <p className="serif--sm serif--md--md mt1">
                  {currentImage.headline}
                </p>
                <p className="mt1 serif--sm serif--md--md">
                  <em>{currentImage.publication}</em>
                </p>
              </article>
            )}
            {currentPDF && (
              <article
                className="pdf"
                style={{
                  ...DEFAULT_STYLE,
                  ...TRANSITION_STYLES[state],
                }}
              >
                <PDFObject url={currentPDF} />
              </article>
            )}
          </div>
        )}
      </Transition>
      <section className="grid-container contained pt0 pt2--md">
        <div className="col c12">
          <p className="serif--sm serif--md--md pb1 pb2--md">
            For press inquiries, please contact{" "}
            <a
              className="link--underline"
              href="mailto:jacqueline@olucompany.com"
            >
              jacqueline@olucompany.com
            </a>
          </p>
        </div>
        {press.map((item, index) => {
          return (
            <div
              key={item._key}
              className={classNames("row", {
                pt1: index > 0,
              })}
            >
              <span className="col c3 c1--md">
                <p className="serif--sm serif--md--md">{item.year}</p>
              </span>
              <span className="col c6 c3--md">
                {item.link ? (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link--underline serif--sm serif--md--md"
                  >
                    <strong>{item.headline}</strong>
                  </a>
                ) : (
                  <a
                    href="#"
                    className="link--underline serif--sm serif--md--md "
                    onClick={e => {
                      e.preventDefault()
                      item.image
                        ? setCurrentImage(item)
                        : setCurrentPDF(item.pdf.asset.url)
                    }}
                  >
                    <strong>{item.headline}</strong>
                  </a>
                )}
                <p className="hide--md serif--sm serif--md--md">
                  {item.publication}
                </p>
              </span>
              <span className="col c3--md show--md">
                <p className="serif--sm serif--md--md">{item.publication}</p>
              </span>
            </div>
          )
        })}
      </section>
    </Layout>
  )
}
